import React from "react"
import {
  Article,
  ArticleHeader,
  ArticleSection,
} from "../../styledComponents/article"
import Layout from "../../components/layout"

const Thankyou = ({ data }) => {

  return (
    <>
    <Layout displayNavBorder>
    <Article itemScope itemType="http://schema.org/Article">
      <ArticleHeader>
        <h1 itemProp="headline">Thank you</h1>
      </ArticleHeader>
      <ArticleSection itemProp="articleBody">
      <p>Please check your email you will have recieved a zoom link for the event. If you have not revieved the email please <a href="mailto:support@kaarmibeing.com">email our KaarmiBeing support team</a>.</p>
      </ArticleSection>
    </Article>
    </Layout>
    </>
  )
}

export default Thankyou
