import styled from "styled-components"
import breakpoints from "./breakpoints"
import { baseGridStyles } from "./base"
import { regular12 } from "./typography"
import avatar from "../css/background/kaarmibeing-team-pic.jpg"


const Article = styled.article`
  ${baseGridStyles}
  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 0 auto;
  }
`

const ArticleHeader = styled.header`
  grid-column: 1 / 5;
  padding: 0;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 1 / 7;
    margin: 0 0 0.8rem;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 1 / 13;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 0 0 1.6rem;
  }

  h1{
    ${props => props.noMargin ? "margin-bottom: 0" : "margin-bottom: 1.6rem"};
    color: var(--green-strong);
  }
  time {
    line-height: 1.6rem;
  }
  
`

const ArticleDate = styled.time`
  margin: 0;
  color: var(--grey-mid-dark);
  ${regular12};
`

const ArticleAuthorInfo = styled.address`
  margin: 1.6rem 0 0;
  color: var(--grey-mid-dark);
  ${regular12};
  span {
    text-indent: -5000px;
    visibility: hidden;
    width: 0;
    display: none;
  }
  a {
    margin-left: 3.4rem;
    position: relative;
    border-bottom: 0;
  }
  a:before {
    content: ' ';
    width: 28px;
    height: 28px;
    background-image: url(${avatar});
    background-repeat: no-repeat;
    background-size: 28px 28px;
    background-position: 0;
    position: absolute;
    left: -32px;
    top: -7px;
  }
`

const ArticleSection = styled.section`
  grid-column: 1 / 5;
  width: 100%;
  @media screen and (min-width: ${breakpoints.sm}px) {
    grid-column: 1 / 7;
    margin: 0;
    padding: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    grid-column: 1 / 9;
  }

  a {
    border-bottom: 1px dotted var(--light-blue-dark);
    &:visited {
      border-bottom: 1px dotted var(--light-blue-darker);
    }

    &:hover,
    &:active,
    &:focus {
      border-bottom: 1px dotted var(--light-blue-dark);
    }
  }

  h2, 
  h3, 
  h4, 
  h5, 
  h6 {
    margin: 1.6rem 0 0 0;
  }

  h2 + h3, 
  h3 + h4, 
  h4 + h5, 
  h5 + h6 {
    margin-top: 0.8rem;
  }

  h2 + h3, 
  h3 + h4, 
  h4 + h5, 
  h5 + h6 {
    margin-top: 0.8rem;
    margin-bottom: 0;
  }

  address {
    font-style: italic;
  }

  * + p,
  * + pre,
  * + ul,
  * + ol,
  * + dl,
  * + dt,
  * + blockquote,
  * + address {
    margin-top: 1.6rem;
  }

  * + ol li,
  * + ul li {
    margin-bottom:0.8rem;
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-bottom:1.6rem;
    }
  }

  .gatsby-image-wrapper {
    @media screen and (min-width: ${breakpoints.md}px) {
      margin-left: 0;
      margin-right: 0;
    }
    @media screen and (min-width: 1304px) {
      margin: 0 auto;
    }
  }

  address {
    font-style: italic;
  }

  figure:first-of-type{
      margin-left: -1.6rem;
      width: 100vw;
      min-width: 32rem;
      @media screen and (min-width: ${breakpoints.sm}px) {
        margin-left: 0;
        width: auto;
      }

      figcaption {
        margin: 0.6rem 1.6rem 0;
      }
  }
`

const ArticleTagsList = styled.ul`
  margin-top: 1.6rem;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media screen and (min-width: ${breakpoints.sm}px) {
    padding-left: 0;
  }
  @media screen and (min-width: ${breakpoints.md}px) {
    padding-left: 0;
  }
  li {
    margin-right: 0.8rem;
    display: inline-flex;
  }

  a {
    text-decoration: none;
    ${regular12};
    border: 1px solid var(--grey-mid);
    padding: 0.4rem 0.8rem;
    border-bottom: 1px solid var(--grey-mid);

    &:hover,
    &:active,
    &:focus {
      border: 1px solid var(--light-blue-dark);
      border-bottom: 1px solid var(--grey-mid);
    }
  }
`

export { Article, ArticleHeader, ArticleSection, ArticleTagsList, ArticleDate, ArticleAuthorInfo }